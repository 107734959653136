import React from 'react';


class Resume extends React.Component {
  render() {
    return(
      <div><h1>Resume Page</h1></div>
    )
  }
}

export default Resume;